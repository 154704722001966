import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../../global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Paper from "@mui/material/Paper";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import PreviewIcon from "@mui/icons-material/Preview";
import DeleteIcon from "@mui/icons-material/Delete";
import Toolbar from "@mui/material/Toolbar";
import { Link } from "react-router-dom";

import Tooltip from "@mui/material/Tooltip";
import { useTheme } from "@mui/material/styles";
import moment from "moment";
import { TextField, Autocomplete } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import BookmarkIcon from '@mui/icons-material/Bookmark';
import IconButton from "@mui/material/IconButton";
import {
  deleteEnquiryThunk,
  getEnquiesTableThunk,
  getEnquiryListData,
} from "./enquirySlice";
import { useSelect } from "@mui/base";
import { useDispatch } from "react-redux";
import ExcelDownload from "../../../utils/components/excelDownload";

// datatablse packages
var $ = require("jquery");
$.DataTable = require("datatables.net");

const axios = require("axios");

const columns = [
  { id: "id", label: "Sr. No", minWidth: 40 },

  {
    id: "companyName",
    label: "Company Name",
    align: "left",
    minWidth: 40,
  },
  {
    id: "address",
    label: "Address",
    align: "left",
    minWidth: 40,
  },
  {
    id: "emailId",
    label: "emailId",
    align: "left",
    minWidth: 40,
  },
  {
    id: "source",
    label: "Source",
    align: "left",
    minWidth: 40,
  },
  {
    id: "rating",
    label: "rating",
    align: "left",
    minWidth: 40,
  },
  {
    id: "leadStatus",
    label: "Lead Status",
    align: "left",
    minWidth: 40,
  },
  {
    id: "createdby",
    label: "createdby",
    align: "left",
    minWidth: 40,
  },
  {
    id: "date",
    label: "Date",
    align: "left",
    minWidth: 40,
  },
  {
    id: "followUpDate",
    label: "Followup Date",
    align: "left",
    minWidth: 40,
  },
  {
    id: "status",
    label: "Status",
    align: "left",
    minWidth: 40,
  },
];

const sourceArr = {
  0: "Visit",
  1: "Tele",
  2: "Justdial",
  3: "Customer Call",
};

const leadStatusArr = {
  0: "Pending",
  1: "Won",
  2: "Lost",
};

function createData(
  id,
  companyName,
  address,
  emailId,
  source,
  createdby,
  leadStatus,
  rating,
  date,
  followUpDate,
  status,
  clientId,
) {
  return {
    id,
    companyName,
    address,
    emailId,
    source,
    createdby,
    leadStatus,
    rating,
    date,
    followUpDate,
    status,
    clientId,
  };
}

export default function EnquiryList() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [enquiryList, setEnquiryList] = React.useState([]);
  const [enquiryData, setEnquiryData] = React.useState([]);
  const [searchBy, setSearchBy] = React.useState("");
  const [searchKey, setSearchKey] = React.useState("");
  const [from, setFrom] = React.useState(null);
  const [to, setTo] = React.useState(null);
  const [totalRows, setTotalRows] = React.useState(0);
  const [quotationData , setQuotationData]= React.useState([])
  const dispatch = useDispatch();
  const data = useSelect(getEnquiryListData);
console.log("quotationData",quotationData)
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const submitSiteDelete = (id) => {
    console.log(id);

    var jsonData = {
      status: -1,
    };
    let url = BASE_URL;
    // dispatch(deleteEnquiryThunk({ id }));
    axios
      .patch(url + `enquiry/${id}`, jsonData)
      .then((res) => {
        toast.success("enquiry was deleted!");
        setTimeout(refresh, 500);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  var refresh = () => {
    window.location.reload(false);
    // <Link to="/master/master/standard">Home</Link>
  };

  const getQuotationData=()=>{
    let url = BASE_URL;
const payload={
  "query":"SELECT enquiryId FROM quotation WHERE enquiryId IS NOT NULL;"
  
  }
    axios
      .post(url + `dynamic`, payload)
      .then((res) => {
    setQuotationData(res.data)
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  }

  function getClientList() {
    let whereConditions = [];
    let pagination_settings = "";
    let whereConditions2 = "";
    let pagination_settings2 = "";

    if (searchBy !== "" && searchKey != "") {
      let colName = {
        "Company Name": "companyName",
      }[searchBy];
      whereConditions.push(`${colName} like '%${searchKey}%'`);
      whereConditions2 = `,(${colName},like,${searchKey})`;
    }

    if (to != null && from != null) {
      whereConditions.push(
        `qt.date between '${moment(from).format("YYYY-MM-DD")}' and '${moment(
          to
        ).format("YYYY-MM-DD")}' `
      );
      whereConditions2 += `,(qt.date,bw,${moment(from).format(
        "YYYY-MM-DD"
      )},${moment(to).format("YYYY-MM-DD")})`;
    }

    whereConditions.push("en.status=1");

    whereConditions =
      whereConditions.length > 0
        ? `where ${whereConditions.join(" and ")}`
        : "";

    if (rowsPerPage != -1) {
      pagination_settings = `limit ${rowsPerPage} offset ${
        rowsPerPage * Number(page)
      }`;
      pagination_settings2 += `&_p=${page}&_size=${rowsPerPage}`;
    }

    let data = {
      query: `select client.companyName,en.clientId,en.contactPersonName,en.emailId,en.status,en.followUpDate,en.date,en.source,en.rating,en.id,en.leadStatus,client.address,client.contact,u.username as createdby from enquiry as en inner join clients as client on en.clientId = client.id inner join users as u on en.createdby = u.id ${whereConditions} order by en.id DESC ${pagination_settings}`,
    };
    axios
      .post(BASE_URL + `dynamic`, data)
      .then((res) => {
        let clientsId = {};
        res.data.map((dt) => (clientsId[dt.createdby] = true));
        clientsId = Object.keys(clientsId).join(",");
        setEnquiryList(
          res.data.map((e) => {
            return {
              ...e,
              source: sourceArr[parseInt(e.source)],
              leadStatus: leadStatusArr[parseInt(e.leadStatus)],
            };
          })
        );
        // return axios
        //   .get(BASE_URL + `users?_where=(id,in,${clientsId})`)
        //   .then((res2) => {
        //     let obj = {};
        //     res2.data.map((clnt) => (obj[clnt.id] = clnt));
        //     setEnquiryList([
        //       ...res.data.map((dt) => {
        //         return {
        //           ...dt,
        //           createdby: obj[dt.createdby]
        //             ? obj[dt.createdby].userName
        //             : "",
        //         };
        //       }),
        //     ]);
        //   });
      })
      .catch((err) => {
        console.log("clients data fetching error: ", err);
      });
  }

  // api calls
  function getTotalRows() {
    axios
      .get(BASE_URL + `enquiry/count`)
      .then(async (res) => {
        setTotalRows(res.data[0]?.no_of_rows);
      })
      .catch((err) => {
        console.log("clients data fetching error: ", err);
      });
  }

  const TablePaginationActions = (props) => {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  };
  const search = () => {
    getClientList();
  };

  useEffect(() => {
    getTotalRows();
    getQuotationData()
  }, []);

  useEffect(() => {
    getClientList();
  }, [rowsPerPage, page]);

  const enquiryDatas = () => {
    var rowDaata = [];
    for (let i = 0; i < enquiryList.length; i++) {
      rowDaata.push(
        createData(
          enquiryList[i].id,
          enquiryList[i].companyName,
          enquiryList[i].address,
          enquiryList[i].emailId,
          enquiryList[i].source,
          enquiryList[i].createdby,
          enquiryList[i].leadStatus,
          enquiryList[i].rating,
        
          enquiryList[i].date
            ? moment(enquiryList[i].date).format("YYYY-MM-DD")
            : "",
          enquiryList[i].followUpDate
            ? moment(enquiryList[i].followUpDate).format("YYYY-MM-DD")
            : "",
            enquiryList[i].status,
            enquiryList[i].clientId,
        )
      );
    }
    setEnquiryData(rowDaata);
  };

  useEffect(() => {
    enquiryDatas();
  }, [enquiryList]);

  return (
    <TableContainer component={Paper} sx={{ mt: 2 }}>
      <Toolbar>
        <Button
          variant="contained"
          size="small"
          component={Link}
          to="/master/enquiry/createEnquiry"
          onClick={() => {}}
        >
          <b>ADD</b>
        </Button>
      </Toolbar>
      <br />
      <div
        className="mb-2"
        style={{ display: "flex", float: "left", justifyContent: "flex-end" }}
      >
        <div style={{ marginLeft: "10px", marginRight: "10px" }}>
          <LocalizationProvider dateAdapter={AdapterDateFns} classNames="mr-2">
            <DatePicker
              label="From"
              value={from ? new Date(from) : ""}
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              onChange={(newValue) => setFrom(newValue)}
              renderInput={(params) => <TextField {...params} size="small" />}
            />
          </LocalizationProvider>
        </div>

        <div style={{ marginRight: "10px" }}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="To"
              value={to ? new Date(to) : ""}
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              onChange={(newValue) => setTo(newValue)}
              renderInput={(params) => <TextField {...params} size="small" />}
              style={{ marginRight: "20px" }}
            />
          </LocalizationProvider>
        </div>
        <Autocomplete
          sx={{
            m: 0,
            minWidth: 180,
            marginLeft: "10px",
          }}
          size="small"
          id="combo-box-demo"
          options={[{ key: "companyName", label: "Company Name" }]}
          renderInput={(params) => <TextField {...params} label="Search By" />}
          onInputChange={(event, newInputValue) => {
            setSearchBy(newInputValue);
          }}
        />
        <TextField
          id="outlined-basic"
          label={"enter " + searchBy}
          size="small"
          variant="outlined"
          onChange={(e) => setSearchKey(e.target.value)}
        />
        <Button
          variant="contained"
          size="small"
          sx={{ m: 0 }}
          style={{ marginLeft: "10px" }}
          onClick={() => {
            search();
          }}
        >
          <SearchIcon />
        </Button>
        <ExcelDownload finalData={enquiryData}/>
      </div>
      <br />

      <table
        stickyHeader
        aria-label="sticky table"
        size="small"
        id="datatable-keytable"
        width="100%"
      >
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{ minWidth: column.minWidth }}
              >
                <b>{column.label}</b>
              </TableCell>
            ))}
            <TableCell>
              <b>Actions</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {enquiryData.map((row, index) => {
            return (
              <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                {columns.map((column) => {
                  let value = row[column.id];
                  if (column.id == "id") {
                    value = index + 1;
                  }
                  return (
                    <TableCell key={column.id} align={column.align}>
                      {column.format && typeof value === "number"
                        ? column.format(value)
                        : value}
                    </TableCell>
                  );
                })}
                <TableCell>
                  <ButtonGroup size="small" aria-label="small button group">
                  <Tooltip title="Edit Client" placement="top-start">
                      <Button
                        component={Link}
                        to={`/master/enquiry/EditEnquiry/${row.id}`}
                      >
                        <PreviewIcon />
                      </Button>
                    </Tooltip>
                  <Tooltip title="Enquiry FollowUp" placement="top-start">
                      <Button
                        component={Link}
                        to={`/master/enquiry/enquiryFollowUp/${row.id}`}
                      >
                        <BookmarkIcon />
                      </Button>
                    </Tooltip>
                    <Tooltip     title={quotationData.some(data => data.enquiryId === row.id) ? "Already Quotation is created" : "Prepare quotation"}
                    placement="top-start">
                      <Button
                        component={Link}
                        to={`/quotation/createQuotation/${row.id}/${row.date}/${row.clientId}`}
                        disabled={quotationData.some(data => data.enquiryId === row.id)}
                      >
                        <ReceiptLongIcon />
                      </Button>
                    </Tooltip>
                   
                    <Tooltip title="Delete Client" placement="top-start">
                      <Button
                        onClick={(e) => {
                          if (window.confirm("Really want to delete client?")) {
                            submitSiteDelete(row.id);
                          }
                        }}
                      >
                        <DeleteIcon style={{ color: "#dc3545" }} />
                      </Button>
                    </Tooltip>
                  </ButtonGroup>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100, { label: "All", value: -1 }]}
        count={totalRows}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        component="div"
        colSpan={3}
        SelectProps={{
          inputProps: {
            "aria-label": "rows per page",
          },
          native: true,
        }}
        ActionsComponent={TablePaginationActions}
      />
      <ToastContainer />
    </TableContainer>
  );
}
