import React from "react";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { Button } from "@mui/material";
const ExcelDownload = ({ finalData }) => {
  const convertToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(finalData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(data, "data.xlsx");
  };
  return (
    <div>
      <Button
        variant="contained"
        size="small"
        sx={{ m: 0 }}
        style={{ marginLeft: "10px", height: "50px" }}
        onClick={convertToExcel}
      >
        Convert to Excel
      </Button>
    </div>
  );
};

export default ExcelDownload;
