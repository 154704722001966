import React, { useEffect } from "react";
import { BASE_URL, DOMAIN } from "./../../global";
import "react-toastify/dist/ReactToastify.css";
import Paper from "@mui/material/Paper";
import Global from "../image/sticker.jpeg";
import TableContainer from "@mui/material/TableContainer";
import { useReactToPrint } from "react-to-print";
import Button from "@mui/material/Button";
import moment from "moment";
import { useParams } from "react-router-dom";
import { Typography } from "@mui/material";
import QRCode from "react-qr-code";
import leftPanel from "../image/leftPanel.jpeg";
import "./certificateList.css";
import {
  countOfStickerAnalytics,
} from "../../utils/analytics/certificateAnalytics";

// datatablse packages
var $ = require("jquery");
$.DataTable = require("datatables.net");

const axios = require("axios");

export default function MultiQr() {
  const printComponentRef = React.useRef();

  const [allData, setAllData] = React.useState([]);
  const params = useParams();
  const fullHeader = true;
  const isHeaderAtLeft = true;
  let certificateIds = params.certificateId;
  const certificateIdsArray = certificateIds.split(",").map(Number);


  const getExpenseList = (event) => {
    let url = BASE_URL;
    axios
      .get(
        url +
          `xjoin?_join=certificate.certificates,_j,inst.instruments,_j,datasheet.datasheets,_j,certificateAnalytics.certificateAnalytics&_on1=(certificate.instrumentId,eq,inst.id)&_on2=(certificate.id,eq,datasheet.id)&_on3=(certificate.id,eq,certificateAnalytics.certificateId)&_fields=certificate.DUCID,certificate.calibrationDate,certificate.nextDueDate,inst.instrumentName,certificate.id,certificateAnalytics.stickerDownloadCounter,certificateAnalytics.certificateDownloadCounter,certificate.locationOfInstrument,certificate.certificateNumber,datasheet.calibratedby,datasheet.revisionNumber&_where=(certificate.id,in,${params.certificateId})`
      )
      .then((res) => {
        var splitIdArray = [];
        params.certificateId
          ? params.certificateId.split(",").map((ids) => {
              for (let i = 0; i < res.data.length; i++) {
                if (parseInt(ids) == res.data[i].certificate_id) {
                  splitIdArray.push(res.data[i]);
                }
              }
            })
          : console.log("no data");
        setAllData(splitIdArray);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handlePrint = useReactToPrint({
    content: () => printComponentRef.current,
  });

  console.log("header pos : ", isHeaderAtLeft ? "row" : "row-reverse");

  const renderQrModal = () => {
    return allData.map((data, index) => {
      return (
        <div className="item">
          <div className="qrModalStyle">
            {fullHeader ? (
              <img
                src={Global}
                style={{ width: "100%", height: "86px", objectFit: "contain" }}
                alt="rq-logo"
              ></img>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: isHeaderAtLeft ? "row" : "row-reverse",
                  height: "40px",
                  padding: "3px",
                }}
              >
                <div style={{ padding: "0px 10px" }}>
                  <img
                    src={Global}
                    style={{
                      height: "100%",
                      maxWidth: "100%",
                      objectFit: "contain",
                    }}
                    alt="rq-logo"
                  ></img>
                </div>
                <div style={{ flex: "1" }}></div>
              </div>
            )}

            <div style={{ flex: "1", display: "flex" }}>
              <div style={{ width: "90%", margin:"10px auto" }}>
                <table
                  style={{
                    width: "100%",
                    height: "100%",
                    tableLayout: "fixed",
                  }}
                >
                  <tbody>
                    <tr>
                      <td className="stickerTdM">
                        <b>CERTIFICATE NO</b>{" "}
                      </td>
                      <td className="dataDivFromStickerM">
                        <b>{`${data.certificate_certificateNumber}${
                          data.datasheet_revisionNumber > 0
                            ? `/AMD-${data.datasheet_revisionNumber}`
                            : ""
                        }`}</b>
                      </td>
                    </tr>
                    <tr>
                      <td className="stickerTdM">
                        <b>INSTRUMENT</b>
                      </td>
                      <td
                        className="dataDivFromStickerM"
                        style={{
                          fontSize:
                            data.inst_instrumentName &&
                            data.inst_instrumentName.length > 30
                              ? "x-small"
                              : "small",
                        }}
                      >
                        <b>{data.inst_instrumentName}</b>
                      </td>
                    </tr>
                    <tr>
                      <td className="stickerTdM">
                        <b>ID NO</b>
                      </td>
                      <td className="dataDivFromStickerM">
                        <b>{data.certificate_DUCID}</b>
                      </td>
                    </tr>
                    <tr>
                      <td className="stickerTdM">
                        <b>LOCATION</b>
                      </td>
                      <td className="dataDivFromStickerM">
                        <b>{data.certificate_locationOfInstrument}</b>
                      </td>
                    </tr>
                    <tr>
                      <td
                        className=" bottomDiv"
                        style={{ display: "flex", border: "none" }}
                        colSpan={2}
                      >
                        <div
                          style={{
                            width: "25%",
                          }}
                        >
                          <div className="bottomDivChildM">
                            <b>CAL DATE</b>
                          </div>
                          <div className="bottomDivChildM">
                            <b>
                              {data.certificate_calibrationDate
                                ? moment(
                                    data.certificate_calibrationDate
                                  ).format("DD-MM-YYYY")
                                : ""}
                            </b>
                          </div>
                        </div>
                        <div
                          style={{
                            width: "25%",
                          }}
                        >
                          <div className="bottomDivChildM">
                            <b>DUE DATE</b>
                          </div>
                          <div className="bottomDivChildM">
                            <b>
                              {data.certificate_nextDueDate
                                ? moment(data.certificate_nextDueDate).format(
                                    "DD-MM-YYYY"
                                  )
                                : ""}
                            </b>
                          </div>
                        </div>
                        <div
                          style={{
                            width: "25%",
                          }}
                        >
                          <div className="bottomDivChildM">
                            <b>CAL BY</b>
                          </div>
                          <div className="bottomDivChildM">
                            <b>
                              {data.datasheet_calibratedby
                                ? data.datasheet_calibratedby
                                : ""}
                            </b>
                          </div>
                        </div>
                        <div
                          style={{
                            width: "25%",
                            display: "flex",
                          }}
                        >
                          <QRCode
                            value={`${DOMAIN}/#/certificate/print/${data.certificate_id}`}
                            size={60}
                            align="right"
                            style={{ margin: "auto" }}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td style={{border: "none" }}></td>
                      <td style={{textAlign: 'right',border: "none" }}>Download No.:{data?.certificateAnalytics_stickerDownloadCounter +1} </td>

                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      );
    });
  };

  useEffect(() => {
    getExpenseList();
  }, []);

  return (
    <TableContainer
      component={Paper}
      sx={{ mt: 2, p: 2 }}
      className="hiddenscroll"
    >
      <Typography variant="h5" component="h5" style={{ float: "center" }}>
        <b>
          <u>Multiple QR Code</u>
        </b>
      </Typography>
      <hr />

      <div
        className="row border-dark p-2 qrSticker"
        ref={printComponentRef}
        style={{ width: "100%", pageBreakAfter: "auto" }}
      >
        <div className="grid">{renderQrModal()}</div>
        <Button
          variant="contained"
          size="small"
          className="non"
          sx={{ m: 0, ml: 2, mt: 4 }}
          onClick={() => {
            handlePrint();
            countOfStickerAnalytics(certificateIdsArray);
          }}
        >
          Print
        </Button>
      </div>
    </TableContainer>
  );
}
